/* ==========================================================================
   reset.css
   ========================================================================== */
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/font-awesome/font/fontawesome-webfont.eot?v=3.2.1");
  src: url("../fonts/font-awesome/font/fontawesome-webfont.eot?#iefix&v=3.2.1") format("embedded-opentype"), url("../fonts/font-awesome/font/fontawesome-webfont.woff?v=3.2.1") format("woff"), url("../fonts/font-awesome/font/fontawesome-webfont.ttf?v=3.2.1") format("truetype"), url("../fonts/font-awesome/font/fontawesome-webfont.svg#fontawesomeregular?v=3.2.1") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  *margin-right: .3em; }

[class^="icon-"]:before, [class*=" icon-"]:before {
  text-decoration: inherit;
  display: inline-block;
  speak: none; }

.icon-large:before {
  vertical-align: -10%;
  font-size: 1.3333333333333333em; }

a [class^="icon-"], a [class*=" icon-"] {
  display: inline; }

[class^="icon-"].icon-fixed-width, [class*=" icon-"].icon-fixed-width {
  display: inline-block;
  width: 1.1428571428571428em;
  text-align: right;
  padding-right: 0.2857142857142857em; }

[class^="icon-"].icon-fixed-width.icon-large, [class*=" icon-"].icon-fixed-width.icon-large {
  width: 1.4285714285714286em; }

.icons-ul {
  margin-left: 2.142857142857143em;
  list-style-type: none; }

.icons-ul > li {
  position: relative; }

.icons-ul .icon-li {
  position: absolute;
  left: -2.142857142857143em;
  width: 2.142857142857143em;
  text-align: center;
  line-height: inherit; }

[class^="icon-"].hide, [class*=" icon-"].hide {
  display: none; }

.icon-muted {
  color: #eeeeee; }

.icon-light {
  color: #ffffff; }

.icon-dark {
  color: #333333; }

.icon-border {
  border: solid 1px #eeeeee;
  padding: .2em .25em .15em;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.icon-2x {
  font-size: 2em; }

.icon-2x.icon-border {
  border-width: 2px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.icon-3x {
  font-size: 3em; }

.icon-3x.icon-border {
  border-width: 3px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.icon-4x {
  font-size: 4em; }

.icon-4x.icon-border {
  border-width: 4px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px; }

.icon-5x {
  font-size: 5em; }

.icon-5x.icon-border {
  border-width: 5px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px; }

.pull-right {
  float: right; }

.pull-left {
  float: left; }

[class^="icon-"].pull-left, [class*=" icon-"].pull-left {
  margin-right: .3em; }

[class^="icon-"].pull-right, [class*=" icon-"].pull-right {
  margin-left: .3em; }

[class^="icon-"], [class*=" icon-"] {
  display: inline;
  width: auto;
  height: auto;
  line-height: normal;
  vertical-align: baseline;
  background-image: none;
  background-position: 0% 0%;
  background-repeat: repeat;
  margin-top: 0; }

.icon-white, .nav-pills > .active > a > [class^="icon-"], .nav-pills > .active > a > [class*=" icon-"], .nav-list > .active > a > [class^="icon-"], .nav-list > .active > a > [class*=" icon-"], .navbar-inverse .nav > .active > a > [class^="icon-"], .navbar-inverse .nav > .active > a > [class*=" icon-"], .dropdown-menu > li > a:hover > [class^="icon-"], .dropdown-menu > li > a:hover > [class*=" icon-"], .dropdown-menu > .active > a > [class^="icon-"], .dropdown-menu > .active > a > [class*=" icon-"], .dropdown-submenu:hover > a > [class^="icon-"], .dropdown-submenu:hover > a > [class*=" icon-"] {
  background-image: none; }

.btn [class^="icon-"].icon-large, .nav [class^="icon-"].icon-large, .btn [class*=" icon-"].icon-large, .nav [class*=" icon-"].icon-large {
  line-height: .9em; }

.btn [class^="icon-"].icon-spin, .nav [class^="icon-"].icon-spin, .btn [class*=" icon-"].icon-spin, .nav [class*=" icon-"].icon-spin {
  display: inline-block; }

.nav-tabs [class^="icon-"], .nav-pills [class^="icon-"], .nav-tabs [class*=" icon-"], .nav-pills [class*=" icon-"], .nav-tabs [class^="icon-"].icon-large, .nav-pills [class^="icon-"].icon-large, .nav-tabs [class*=" icon-"].icon-large, .nav-pills [class*=" icon-"].icon-large {
  line-height: .9em; }

.btn [class^="icon-"].pull-left.icon-2x, .btn [class*=" icon-"].pull-left.icon-2x, .btn [class^="icon-"].pull-right.icon-2x, .btn [class*=" icon-"].pull-right.icon-2x {
  margin-top: .18em; }

.btn [class^="icon-"].icon-spin.icon-large, .btn [class*=" icon-"].icon-spin.icon-large {
  line-height: .8em; }

.btn.btn-small [class^="icon-"].pull-left.icon-2x, .btn.btn-small [class*=" icon-"].pull-left.icon-2x, .btn.btn-small [class^="icon-"].pull-right.icon-2x, .btn.btn-small [class*=" icon-"].pull-right.icon-2x {
  margin-top: .25em; }

.btn.btn-large [class^="icon-"], .btn.btn-large [class*=" icon-"] {
  margin-top: 0; }

.btn.btn-large [class^="icon-"].pull-left.icon-2x, .btn.btn-large [class*=" icon-"].pull-left.icon-2x, .btn.btn-large [class^="icon-"].pull-right.icon-2x, .btn.btn-large [class*=" icon-"].pull-right.icon-2x {
  margin-top: .05em; }

.btn.btn-large [class^="icon-"].pull-left.icon-2x, .btn.btn-large [class*=" icon-"].pull-left.icon-2x {
  margin-right: .2em; }

.btn.btn-large [class^="icon-"].pull-right.icon-2x, .btn.btn-large [class*=" icon-"].pull-right.icon-2x {
  margin-left: .2em; }

.nav-list [class^="icon-"], .nav-list [class*=" icon-"] {
  line-height: inherit; }

.icon-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: -35%; }

.icon-stack [class^="icon-"], .icon-stack [class*=" icon-"] {
  display: block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 1em;
  line-height: inherit;
  *line-height: 2em; }

.icon-stack .icon-stack-base {
  font-size: 2em;
  *line-height: 1em; }

.icon-spin {
  display: inline-block;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear; }

a .icon-stack, a .icon-spin {
  display: inline-block;
  text-decoration: none; }

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(359deg); } }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg); } }

@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg); }
  100% {
    -o-transform: rotate(359deg); } }

@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg); }
  100% {
    -ms-transform: rotate(359deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

.icon-rotate-90:before {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1); }

.icon-rotate-180:before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2); }

.icon-rotate-270:before {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3); }

.icon-flip-horizontal:before {
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1); }

.icon-flip-vertical:before {
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1); }

a .icon-rotate-90:before, a .icon-rotate-180:before, a .icon-rotate-270:before, a .icon-flip-horizontal:before, a .icon-flip-vertical:before {
  display: inline-block; }

.icon-envelope:before {
  content: "\f0e0"; }

.icon-phone:before {
  content: "\f095"; }

.icon-map-marker:before {
  content: "\f041"; }

/* ==========================================================================
   base.css
   ========================================================================== */
@font-face {
  font-family: BebasNeue;
  src: url(../../fonts/BebasNeue.otf);
  src: url(../../fonts/bebasneue-webfont.eot);
  src: url(../../fonts/bebasneue-webfont.eot?#iefix) format("embedded-opentype"), url(../fonts/bebasneue-webfont.woff) format("woff"), url(../fonts/bebasneue-webfont.ttf) format("truetype"), url(../fonts/bebasneue-webfont.svg#bebas_neueregular) format("svg"); }

html {
  color: #222;
  font-size: 1em;
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

body {
  background: #ffffff;
  color: #474747;
  font-family: Verdana, Geneva, sans-serif;
  font-size: 10px; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

h1,
h2,
h3,
h4,
label {
  font-family: BebasNeue;
  text-decoration: none;
  letter-spacing: 1px; }

h1,
h2 {
  font-size: 2.8em;
  color: #474747;
  text-align: center; }

h1 {
  margin: 22px 0 30px 0; }

h2 {
  line-height: 1.1em;
  margin: 0 0 15px 0; }

h3,
label {
  font-size: 2em;
  margin: 0;
  line-height: 1.1em;
  font-style: normal !important; }

h4 {
  font-size: 1.6em;
  color: #474747;
  margin: 0px; }

p,
span {
  font-size: 1.6em;
  line-height: 1.3em; }

.btn {
  border-radius: 0; }

#homepage p {
  font-family: BebasNeue;
  text-decoration: none;
  line-height: 1.1em;
  text-transform: uppercase; }

a {
  color: #48b348;
  text-decoration: none; }

a,
a:hover,
a:focus {
  cursor: pointer; }

em {
  font-style: italic;
  font-family: BebasNeue; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

img {
  border: none; }

.pull-left {
  float: left; }

.pull-right {
  float: right; }

.center {
  margin: 0 auto;
  text-align: center; }

.justified {
  text-align: justify; }

.clear {
  clear: both;
  display: block;
  font-size: 0;
  line-height: 0; }

/* ==========================================================================
   layout.css
   ========================================================================== */
/* Sliding Navigation*/
.prev,
.next {
  display: none; }

.loading {
  width: 100%;
  height: 500px;
  background: url(../images/loading.gif) no-repeat center center;
  position: absolute;
  z-index: 100; }

#contentSlide {
  width: 100%;
  min-height: 0 !important;
  overflow: hidden;
  background: #f2f2f2; }

.pageContainer {
  position: absolute;
  width: 100%;
  min-height: 100%;
  float: none !important;
  z-index: 4900;
  top: 0;
  background: #f2f2f2; }

.wrap,
.header-content {
  margin: 0 auto;
  width: 80%;
  max-width: 1200px; }

header {
  position: fixed;
  display: flex;
  align-items: center;
  height: 65px;
  width: 100%;
  z-index: 5000;
  background: #ffffff; }

.wrap {
  padding-top: 65px; }

/* Header */
.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .header-content .set-size {
    position: relative; }

.shadow {
  background: url(../images/top-shadow-medium-2.png) repeat-x;
  height: 15px;
  position: absolute;
  top: 65px;
  width: 100%;
  z-index: 5000; }

/*Body*/
.whiteBox,
.footerBox {
  position: relative;
  width: 100%;
  z-index: 2;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0.95);
  float: left;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3); }

.whiteBox {
  min-height: 640px;
  padding: 0 1.6em 4em 1.6em; }

.footerBox span {
  font-size: 1.1em;
  color: #858484; }

/*Responsive Grid*/
/*  SECTIONS  */
.section {
  clear: both;
  padding: 0;
  margin: 0; }

/*  COLUMN SETUP  */
.col {
  display: block;
  float: left;
  margin: 1% 0 1% 1.6%; }

.col:first-child {
  margin-left: 0; }

/*  GROUPING  */
.group:before,
.group:after {
  content: "";
  display: table; }

.group:after {
  clear: both; }

.group {
  zoom: 1;
  /* For IE 6/7 */ }

/*  GRID OF TWO  */
.span_2_of_2 {
  width: 100%; }

.span_1_of_2 {
  width: 49.2%; }

.span_3_of_3 {
  width: 100%; }

.span_2_of_3 {
  width: 66.13%; }

.span_1_of_3 {
  width: 32.26%; }

/*  GO FULL WIDTH AT LESS THAN 480 PIXELS */
@media only screen and (max-width: 745px) {
  .col {
    margin: 0 0 30px 0; }
  .span_2_of_2,
  .span_1_of_2 {
    width: 100%; }
  .span_3_of_3,
  .span_2_of_3,
  .span_1_of_3 {
    width: 100%; }
  .lastColumn {
    margin-top: 20px; } }

/* ==========================================================================
   module.css
   ========================================================================== */
/* Upgrade Message */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

/* Carousel on Homepage */
.carousel {
  position: relative;
  margin-top: 30px;
  max-width: 920px;
  text-align: center;
  margin: 0 auto; }
  .carousel .carousel-inner {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 53.26%;
    overflow: hidden;
    background-color: #f2f2f2; }
    .carousel .carousel-inner .item {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      -webkit-transition: 0.6s ease-in-out left;
      transition: 0.6s ease-in-out left;
      padding: 0 12%; }
      .carousel .carousel-inner .item p {
        margin: 0; }
      .carousel .carousel-inner .item.slider-1 {
        background-image: url(../images/slider_1.png);
        color: #ffffff; }
        .carousel .carousel-inner .item.slider-1 p {
          position: absolute;
          right: 0;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          padding: 0 12%;
          font-size: 6em; }
      .carousel .carousel-inner .item.slider-2 p {
        margin-top: 40px;
        font-size: 5em;
        color: #474747; }
      .carousel .carousel-inner .item.slider-2 .logo-container-row {
        display: flex;
        margin-top: 10%; }
        .carousel .carousel-inner .item.slider-2 .logo-container-row .logo-image {
          height: 70px; }
        .carousel .carousel-inner .item.slider-2 .logo-container-row.row-1 {
          justify-content: space-between; }
        .carousel .carousel-inner .item.slider-2 .logo-container-row.row-2 {
          justify-content: space-around; }
    .carousel .carousel-inner .active,
    .carousel .carousel-inner .next,
    .carousel .carousel-inner .prev {
      display: block; }
    .carousel .carousel-inner .active {
      left: 0; }
    .carousel .carousel-inner .next,
    .carousel .carousel-inner .prev {
      position: absolute;
      top: 0;
      width: 100%; }
    .carousel .carousel-inner .next {
      left: 100%; }
    .carousel .carousel-inner .prev {
      left: -100%; }
    .carousel .carousel-inner .next.left,
    .carousel .carousel-inner .prev.right {
      left: 0; }
    .carousel .carousel-inner .active.left {
      left: -100%; }
    .carousel .carousel-inner .active.right {
      left: 100%; }
  .carousel .carousel-control {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 15%;
    opacity: 0.7;
    filter: alpha(opacity=70);
    font-size: 50px;
    color: #ffffff;
    text-align: center;
    text-shadow: 0 1px 5px black; }
  .carousel .carousel-control.right {
    left: auto;
    right: 0; }
  .carousel .carousel-control:hover,
  .carousel .carousel-control:focus {
    color: #ffffff;
    text-decoration: none;
    opacity: 0.9;
    filter: alpha(opacity=90); }
  .carousel .carousel-control .icon-prev,
  .carousel .carousel-control .icon-next,
  .carousel .carousel-control .glyphicon-chevron-left,
  .carousel .carousel-control .glyphicon-chevron-right {
    position: absolute;
    top: 40%;
    left: 50%;
    z-index: 5;
    display: inline-block; }
  .carousel .carousel-control .icon-prev,
  .carousel .carousel-control .icon-next {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px; }
  .carousel .carousel-control .icon-prev:before {
    content: '\2039'; }
  .carousel .carousel-control .icon-next:before {
    content: '\203a'; }
  .carousel .carousel-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 15;
    width: 60%;
    margin-left: -30%;
    padding-left: 0;
    list-style: none;
    text-align: center; }
  .carousel .carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    cursor: pointer; }
  .carousel .carousel-indicators .active {
    margin: 0;
    width: 12px;
    height: 12px;
    background-color: #ffffff; }
  .carousel .carousel-caption {
    position: absolute;
    left: 15%;
    right: 15%;
    bottom: 20px;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #ffffff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6); }
  .carousel .carousel-caption .btn {
    text-shadow: none; }

/* Primary Button */
.btn {
  font-family: BebasNeue;
  font-weight: 100;
  text-align: center;
  letter-spacing: 0.125em;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  user-select: none; }

.btn-primary {
  color: #ffffff;
  background-color: #428bca;
  border-color: #357ebd; }

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: #ffffff;
  background-color: #3276b1;
  border-color: #285e8e; }

.btn {
  font-size: 2em; }

/* NavButtons */
#menu {
  font-family: BebasNeue;
  font-size: 2.7em; }
  #menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-family: BebasNeue; }
  #menu a {
    text-decoration: none;
    font-family: BebasNeue; }
  #menu li {
    position: relative;
    display: inline; }
  #menu li:not(last-of-type) {
    padding: 0 15px; }
  #menu > ul > li > a {
    line-height: 1.4em;
    color: #858484; }
  #menu > ul > li:hover > a {
    color: #474747; }
  #menu > ul > li > a.current {
    color: #474747; }
  #menu .menu-label {
    padding-right: 0; }
  #menu .menu-drop:hover .menu-label {
    background-position: right -23px; }
  #menu .menu-right {
    float: right; }
  #menu li:hover ul {
    left: 0;
    opacity: 1; }

/* Progress Bars */
.progress {
  width: 100%;
  overflow: hidden;
  height: 20px;
  margin-bottom: 20px;
  background-color: #f7f7f7;
  background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
  background-repeat: repeat-x;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0; }

.progress .bar {
  width: 0;
  height: 100%;
  color: #ffffff;
  float: left;
  font-size: 0.75em;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #428bca;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  transition: width 0.6s ease; }

.progress .bar + .bar {
  box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(0, 0, 0, 0.15); }

/* Portfolio Gallery */
.featherlight .featherlight-content {
  width: 95%;
  max-width: 850px; }
  .featherlight .featherlight-content .featherlight-close-icon {
    color: #474747; }
  .featherlight .featherlight-content .featherlight-inner {
    position: relative;
    min-height: 550px;
    display: flex;
    align-items: center;
    background-color: #f2f2f2; }
  .featherlight .featherlight-content .featherlight-previous span,
  .featherlight .featherlight-content .featherlight-next span {
    color: #474747;
    width: initial; }
  .featherlight .featherlight-content .featherlight-next:hover,
  .featherlight .featherlight-content .featherlight-previous:hover {
    background: rgba(0, 0, 0, 0.1); }
  .featherlight .featherlight-content .featherlight-previous {
    right: 90%; }
  .featherlight .featherlight-content .featherlight-next {
    left: 90%; }
  .featherlight .featherlight-content .contentLeft {
    position: relative;
    margin-right: 290px;
    width: 100%;
    text-align: center; }
    .featherlight .featherlight-content .contentLeft img {
      width: 95%; }
    .featherlight .featherlight-content .contentLeft a {
      position: absolute;
      margin-top: 20px;
      right: 50%;
      bottom: 0;
      transform: translate(50%, 55px); }
  .featherlight .featherlight-content .contentRight {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 290px;
    padding: 0 15px;
    background-color: white; }
    .featherlight .featherlight-content .contentRight h2 {
      margin: 0 0 15px 0; }
    .featherlight .featherlight-content .contentRight p,
    .featherlight .featherlight-content .contentRight li {
      font-size: 1.5em;
      line-height: 1.3em; }
    .featherlight .featherlight-content .contentRight ul {
      margin: 5px 0;
      padding-left: 15px; }
  .featherlight .featherlight-content #academicPortfolio h2 {
    margin: 0 0 5px 0; }
  .featherlight .featherlight-content #academicPortfolio .leonidTitle,
  .featherlight .featherlight-content #academicPortfolio .leonidUniversity {
    display: block;
    font-family: "BebasNeue";
    text-align: center;
    letter-spacing: 1px; }
  @media screen and (max-width: 1024px) {
    .featherlight .featherlight-content .featherlight-close-icon {
      width: initial;
      line-height: 30px;
      padding: 10px;
      font-size: 30px; }
    .featherlight .featherlight-content .featherlight-previous,
    .featherlight .featherlight-content .featherlight-next {
      top: 50%;
      bottom: initial; } }
  @media screen and (max-width: 768px) {
    .featherlight .featherlight-content {
      position: static;
      height: 98%; }
      .featherlight .featherlight-content .featherlight-close-icon {
        top: 1%;
        right: 2.5%; }
      .featherlight .featherlight-content .featherlight-inner {
        display: block;
        min-height: initial; }
      .featherlight .featherlight-content .contentLeft {
        margin: 0; }
        .featherlight .featherlight-content .contentLeft img {
          width: 85%; }
      .featherlight .featherlight-content .contentRight {
        position: static;
        width: 100%;
        margin-top: 70px;
        padding: 0 30px; }
        .featherlight .featherlight-content .contentRight h2 {
          padding-top: 25px; } }

/* Portfolio Images */
a.gallery img {
  width: 100%;
  transition: opacity .3s linear;
  backface-visibility: hidden;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0'/></filter></svg>#grayscale"); }

a.gallery img:hover {
  cursor: pointer;
  opacity: 0.75; }

/* Contact Page */
#contact p {
  margin-top: 5px; }

.contactItem {
  margin-top: 60px;
  font-size: 1.6em; }

i {
  color: #48b348;
  margin-right: 12px; }

#contactForm #contact-input {
  width: 100%;
  height: 0px;
  background: #ffffff;
  background-color: rgba(255, 255, 255, 0.95); }

#contactForm #contact-us {
  width: 100%; }

#contactForm .formblock {
  display: block;
  width: 100%;
  margin: 0 0 11px 0; }
  #contactForm .formblock label {
    display: block;
    font-weight: normal; }
  #contactForm .formblock .txt,
  #contactForm .formblock textarea {
    font-size: 1.6em;
    padding: 6px 6px;
    color: #666;
    width: 100%;
    border: 1px solid #979797; }
  #contactForm .formblock textarea {
    height: 160px; }

#contactForm .formblock .txt:focus,
#contactForm textarea:focus {
  border: 1px solid #74b9f0;
  outline: none; }

#contactForm .formblock .error {
  font-weight: bold;
  font-style: normal;
  color: #9d3131; }

#contactForm .info {
  font-weight: bold;
  color: #48b348;
  margin-bottom: 10px; }

#contactForm p.tick {
  text-decoration: none;
  color: #474747;
  line-height: 1.9em;
  font-weight: normal; }

#contactForm .subbutton {
  float: right; }

/* ==========================================================================
   state.css
   ========================================================================== */
.hidden {
  display: none !important;
  visibility: hidden; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.mobile {
  display: none; }

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

/* ==========================================================================
   media-queries.css
   ========================================================================== */
/*Tablet*/
@media screen and (max-width: 1024px) {
  /*General Styles Tablet*/
  html {
    overflow-y: auto; }
  .header-content {
    width: 100%; }
  .wrap {
    width: 100%; }
  /* Carousel Tablet */
  .carousel-inner {
    position: relative;
    overflow: hidden;
    width: 100%; }
  .carousel-control .icon-prev,
  .carousel-control .icon-next {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px; }
  .carousel-caption {
    left: 20%;
    right: 20%;
    padding-bottom: 30px; }
  .carousel-indicators {
    bottom: 20px; } }

/*Mobile*/
@media only screen and (max-width: 745px) {
  /*General Styles Mobile*/
  body {
    font-size: 10px; }
  .desktop {
    display: none; }
  .mobile {
    display: block; }
  .wrap {
    padding-top: 38px; }
  h4 {
    font-size: 2em; }
  /* NavBar Mobile */
  header {
    height: 38px; }
  header #logo {
    width: 105px;
    text-align: center; }
  header #logo img {
    width: 105px;
    height: 27.5px; }
  .shadow {
    top: 38px; }
  #menu {
    font-size: 1.7em; }
  #menu > ul > li {
    padding: 0 5px;
    line-height: 1.4em; }
  #homepage p {
    font-size: 4.38em;
    color: #ffffff; }
  #about p {
    font-size: 1.4em; }
  #contact p {
    font-size: 1.7em; }
  .formblock .txt,
  .formblock .txtarea {
    font-size: 16px; }
  .background {
    background-image: url(../images/ny_mobile.jpg); }
  .download-resume {
    display: block; }
  .progress {
    width: 100%; }
  .contactItem {
    font-size: 1.7em;
    margin-top: 30px; } }

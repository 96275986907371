/* ==========================================================================
   module.css
   ========================================================================== */

/* Upgrade Message */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}


/* Carousel on Homepage */
.carousel {
  position: relative;
  margin-top: 30px;
  max-width: 920px;
  text-align: center;
  margin: 0 auto;

  .carousel-inner {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 53.26%;
    overflow: hidden;
    background-color: #f2f2f2;

    .item {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      -webkit-transition: 0.6s ease-in-out left;
      transition: 0.6s ease-in-out left;
      padding: 0 12%;

      p {
        margin: 0;
      }

      &.slider-1 {
        background-image: url(../images/slider_1.png);
        color: $white;

        p {
          position: absolute;
          right: 0;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          padding: 0 12%;
          font-size: 6em;
        }
      }

      &.slider-2 {
        p {
          margin-top: 40px;
          font-size: 5em;
          color: #474747;
        }

        .logo-container-row {
          display: flex;
          margin-top: 10%;

          .logo-image {
            height: 70px;
          }

          &.row-1 {
            justify-content: space-between;
          }

          &.row-2 {
            justify-content: space-around;
          }
        }
      }
    }

    .active,
    .next,
    .prev {
      display: block;
    }

    .active {
      left: 0;
    }

    .next,
    .prev {
      position: absolute;
      top: 0;
      width: 100%;
    }

    .next {
      left: 100%;
    }

    .prev {
      left: -100%;
    }

    .next.left,
    .prev.right {
      left: 0;
    }

    .active.left {
      left: -100%;
    }

    .active.right {
      left: 100%;
    }
  }

  .carousel-control {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 15%;
    opacity: 0.7;
    filter: alpha(opacity=70);
    font-size: 50px;
    color: $white;
    text-align: center;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 1);
  }

  .carousel-control.right {
    left: auto;
    right: 0;
  }

  .carousel-control:hover,
  .carousel-control:focus {
    color: $white;
    text-decoration: none;
    opacity: 0.9;
    filter: alpha(opacity=90);
  }

  .carousel-control .icon-prev,
  .carousel-control .icon-next,
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right {
    position: absolute;
    top: 40%;
    left: 50%;
    z-index: 5;
    display: inline-block;
  }

  .carousel-control .icon-prev,
  .carousel-control .icon-next {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
  }

  .carousel-control .icon-prev:before {
    content: '\2039';
  }

  .carousel-control .icon-next:before {
    content: '\203a';
  }

  .carousel-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 15;
    width: 60%;
    margin-left: -30%;
    padding-left: 0;
    list-style: none;
    text-align: center;
  }

  .carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    border: 1px solid $white;
    border-radius: 10px;
    cursor: pointer;
  }

  .carousel-indicators .active {
    margin: 0;
    width: 12px;
    height: 12px;
    background-color: $white;
  }

  .carousel-caption {
    position: absolute;
    left: 15%;
    right: 15%;
    bottom: 20px;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: $white;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  }

  .carousel-caption .btn {
    text-shadow: none;
  }
}


/* Primary Button */
.btn {
  font-family: BebasNeue;
  font-weight: 100;
  text-align: center;
  letter-spacing: 0.125em;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  user-select: none;
}

.btn-primary {
  color: $white;
  background-color: #428bca;
  border-color: #357ebd;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: $white;
  background-color: #3276b1;
  border-color: #285e8e;
}

.btn {
  font-size: 2em;
}


/* NavButtons */
#menu {
  font-family: BebasNeue;
  font-size: 2.7em;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-family: BebasNeue;
  }

  a {
    text-decoration: none;
    font-family: BebasNeue;
  }

  li {
    position: relative;
    display: inline;
  }

  li:not(last-of-type) {
    padding: 0 15px;
  }

  > ul > li > a {
    line-height: 1.4em;
    color: $md-grey;
  }

  > ul > li:hover > a {
    color: $dk-grey;
  }

  > ul > li > a.current {
    color: $dk-grey;
  }

  .menu-label {
    padding-right: 0;
  }

  .menu-drop:hover .menu-label {
    background-position: right -23px;
  }

  .menu-right {
    float: right;
  }

  li:hover ul {
    left: 0;
    opacity: 1;
  }
}


/* Progress Bars */
.progress {
  width: 100%;
  overflow: hidden;
  height: 20px;
  margin-bottom: 20px;
  background-color: #f7f7f7;
  background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
  background-repeat: repeat-x;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0;
}

.progress .bar {
  width: 0;
  height: 100%;
  color: $white;
  float: left;
  font-size: 0.75em;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #428bca;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  transition: width 0.6s ease;
}

.progress .bar + .bar {
  box-shadow: inset 1px 0 0 rgba(0, 0, 0, .15), inset 0 -1px 0 rgba(0, 0, 0, .15);
}


/* Portfolio Gallery */
.featherlight .featherlight-content {
  width: 95%;
  max-width: 850px;

  .featherlight-close-icon {
    color: $dk-grey;
  }

  .featherlight-inner {
    position: relative;
    min-height: 550px;
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
  }

  .featherlight-previous,
  .featherlight-next {
    span {
      color: $dk-grey;
      width: initial;
    }
  }

  .featherlight-next:hover,
  .featherlight-previous:hover {
    background: rgba(0,0,0,.10)
  }

  .featherlight-previous {
    right: 90%;
  }

  .featherlight-next {
    left: 90%;
  }

  .contentLeft {
    position: relative;
    margin-right: 290px;
    width: 100%;
    text-align: center;

    img {
      width: 95%;
    }

    a {
      position: absolute;
      margin-top: 20px;
      right: 50%;
      bottom: 0;
      transform: translate(50%, 55px);
    }
  }

  .contentRight {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 290px;
    padding: 0 15px;
    background-color: white;

    h2 {
      margin: 0 0 15px 0;
    }

    p,
    li {
      font-size: 1.5em;
      line-height: 1.3em;
    }

    ul {
      margin: 5px 0;
      padding-left: 15px;
    }
  }

  #academicPortfolio {
    h2 {
      margin: 0 0 5px 0;
    }

    .leonidTitle,
    .leonidUniversity {
      display: block;
      font-family: "BebasNeue";
      text-align: center;
      letter-spacing: 1px;
    }
  }

  @media screen and (max-width: 1024px) {
    .featherlight-close-icon {
      width: initial;
      line-height: 30px;
      padding: 10px;
      font-size: 30px;
    }

    .featherlight-previous,
    .featherlight-next {
      top: 50%;
      bottom: initial;
    }
  }

  @media screen and (max-width: 768px) {
    position: static;
    height: 98%;

    .featherlight-close-icon {
      top: 1%;
      right: 2.5%;
    }

    .featherlight-inner {
      display: block;
      min-height: initial;
    }

    .contentLeft {
      margin: 0;

      img {
        width: 85%;
      }
    }

    .contentRight {
      position: static;
      width: 100%;
      margin-top: 70px;
      padding: 0 30px;

      h2 {
        padding-top: 25px;
      }
    }
  }
}


/* Portfolio Images */
a.gallery img {
  width: 100%;
  transition: opacity .3s linear;
  backface-visibility: hidden;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
}

a.gallery img:hover {
  cursor: pointer;
  opacity: 0.75;
}


/* Contact Page */
#contact p {
  margin-top: 5px;
}

.contactItem {
  margin-top: 60px;
  font-size: 1.6em;
}

i {
  color: #48b348;
  margin-right: 12px;
}

#contactForm {
  #contact-input {
    width: 100%;
    height: 0px;
    background: $white;
    background-color: rgba(255, 255, 255, 0.95);
  }

  #contact-us {
    width: 100%;
  }

  .formblock {
    display: block;
    width: 100%;
    margin: 0 0 11px 0;

    label {
      display: block;
      font-weight: normal;
    }

    .txt,
    textarea {
      font-size: 1.6em;
      padding: 6px 6px;
      color: #666;
      width: 100%;
      border: 1px solid #979797;
    }

    textarea {
      height: 160px;
    }
  }

  .formblock .txt:focus,
  textarea:focus {
    border: 1px solid #74b9f0;
    outline: none;
  }

  .formblock .error {
    font-weight: bold;
    font-style: normal;
    color: #9d3131;
  }

  .info {
    font-weight: bold;
    color: #48b348;
    margin-bottom: 10px;
  }

  p.tick {
    text-decoration: none;
    color: $dk-grey;
    line-height: 1.9em;
    font-weight: normal;
  }

  .subbutton {
    float: right;
  }
}

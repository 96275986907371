/* ==========================================================================
   base.css
   ========================================================================== */

$white: #ffffff;
$light-grey: #f2f2f2;
$md-grey: #858484;
$dk-grey: #474747;

@font-face {
  font-family: BebasNeue;
  src: url(../../fonts/BebasNeue.otf);
  src: url(../../fonts/bebasneue-webfont.eot);
  src: url(../../fonts/bebasneue-webfont.eot?#iefix) format('embedded-opentype'), url(../fonts/bebasneue-webfont.woff) format('woff'), url(../fonts/bebasneue-webfont.ttf) format('truetype'), url(../fonts/bebasneue-webfont.svg#bebas_neueregular) format('svg');
}

html {
  color: #222;
  font-size: 1em;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background: $white;
  color: $dk-grey;
  font-family: Verdana, Geneva, sans-serif;
  font-size: 10px;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

h1,
h2,
h3,
h4,
label {
  font-family: BebasNeue;
  text-decoration: none;
  letter-spacing: 1px;
}

h1,
h2 {
  font-size: 2.8em;
  color: $dk-grey;
  text-align: center;
}

h1 {
  margin: 22px 0 30px 0;
}

h2 {
  line-height: 1.1em;
  margin: 0 0 15px 0;
}

h3,
label {
  font-size: 2em;
  margin: 0;
  line-height: 1.1em;
  font-style: normal !important;
}

h4 {
  font-size: 1.6em;
  color: $dk-grey;
  margin: 0px;
}

p,
span {
  font-size: 1.6em;
  line-height: 1.3em;
}

.btn {
  border-radius: 0;
}

#homepage p {
  font-family: BebasNeue;
  text-decoration: none;
  line-height: 1.1em;
  text-transform: uppercase;
}

a {
  color: #48b348;
  text-decoration: none;
}

a,
a:hover,
a:focus {
  cursor: pointer;
}

em {
  font-style: italic;
  font-family: BebasNeue;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

img {
  border: none;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.center {
  margin: 0 auto;
  text-align: center;
}

.justified {
  text-align: justify;
}

.clear {
  clear: both;
  display: block;
  font-size: 0;
  line-height: 0;
}

/* ==========================================================================
   media-queries.css
   ========================================================================== */

/*Tablet*/
@media screen and (max-width: 1024px) {
  /*General Styles Tablet*/
  html {
     overflow-y: auto;
  }

  .header-content {
    width: 100%;
  }

  .wrap {
     width: 100%;
  }

  /* Carousel Tablet */
  .carousel-inner {
     position: relative;
     overflow: hidden;
     width: 100%;
  }
  .carousel-control .icon-prev,
  .carousel-control .icon-next {
     width: 30px;
     height: 30px;
     margin-top: -15px;
     margin-left: -15px;
  }
  .carousel-caption {
     left: 20%;
     right: 20%;
     padding-bottom: 30px;
  }
  .carousel-indicators {
     bottom: 20px;
  }
}

/*Mobile*/
@media only screen and (max-width: 745px) {
  /*General Styles Mobile*/
  body {
    font-size: 10px;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .wrap {
    padding-top: 38px;
  }

  h4 {
    font-size: 2em;
  }

  /* NavBar Mobile */
  header {
    height: 38px;
  }

  header #logo {
    width: 105px;
    text-align: center;
  }

  header #logo img {
    width: 105px;
    height: 27.5px;
  }

  .shadow {
    top: 38px;
  }

  #menu {
    font-size: 1.7em;
  }

  #menu > ul > li {
     padding: 0 5px;
     line-height: 1.4em;
  }

  #homepage p {
    font-size: 4.38em;
    color: $white;
  }

  #about p {
    font-size: 1.4em;
  }

  #contact p {
    font-size: 1.7em;
  }

  .formblock .txt,
  .formblock .txtarea {
     font-size: 16px;
  }

  .background {
     background-image: url(../images/ny_mobile.jpg);
  }

  .download-resume {
    display: block;
  }

  .progress {
    width: 100%;
  }

  .contactItem {
    font-size: 1.7em;
    margin-top: 30px;
  }
}

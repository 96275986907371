/* ==========================================================================
   layout.css
   ========================================================================== */

/* Sliding Navigation*/
.prev,
.next {
  display: none;
}

.loading {
  width: 100%;
  height: 500px;
  background: url(../images/loading.gif) no-repeat center center;
  position: absolute;
  z-index: 100;
}

#contentSlide {
  width: 100%;
  min-height: 0 !important;
  overflow: hidden;
  background: $light-grey;
}

.pageContainer {
  position: absolute;
  width: 100%;
  min-height: 100%;
  float: none !important;
  z-index: 4900;
  top: 0;
  background: $light-grey;
}

.wrap,
.header-content {
  margin: 0 auto;
  width: 80%;
  max-width: 1200px;
}

header {
  position: fixed;
  display: flex;
  align-items: center;
  height: 65px;
  width: 100%;
  z-index: 5000;
  background: $white;
}

.wrap {
  padding-top: 65px;
}


/* Header */
.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .set-size {
    position: relative;
  }
}

.shadow {
  background: url(../images/top-shadow-medium-2.png) repeat-x;
  height: 15px;
  position: absolute;
  top: 65px;
  width: 100%;
  z-index: 5000;
}


/*Body*/
.whiteBox,
.footerBox {
  position: relative;
  width: 100%;
  z-index: 2;
  background-color: $white;
  background-color: rgba(255, 255, 255, 0.95);
  float: left;
  box-shadow: 0 0 4px rgba(0, 0, 0, .3);
}

.whiteBox {
	min-height: 640px;
  padding: 0 1.6em 4em 1.6em;
}

.footerBox span {
  font-size: 1.1em;
  color: $md-grey;
}


/*Responsive Grid*/
/*  SECTIONS  */
.section {
	clear: both;
	padding: 0;
	margin: 0;
}

/*  COLUMN SETUP  */
.col {
	display: block;
	float:left;
	margin: 1% 0 1% 1.6%;
}
.col:first-child {
	margin-left: 0;
}

/*  GROUPING  */
.group:before,
.group:after {
	content:""; display:table;
}
.group:after {
	clear:both;
}
.group {
	zoom:1; /* For IE 6/7 */
}
/*  GRID OF TWO  */
.span_2_of_2 {
	width: 100%;
}
.span_1_of_2 {
	width: 49.2%;
}
.span_3_of_3 {
	width: 100%;
}
.span_2_of_3 {
	width: 66.13%;
}
.span_1_of_3 {
	width: 32.26%;
}

/*  GO FULL WIDTH AT LESS THAN 480 PIXELS */
@media only screen and (max-width: 745px) {
	.col {
		margin: 0 0 30px 0;
	}

  .span_2_of_2,
  .span_1_of_2 {
    width: 100%;
  }

  .span_3_of_3,
  .span_2_of_3,
  .span_1_of_3 {
    width: 100%;
  }

  .lastColumn {
    margin-top: 20px;
  }
}
